@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}
.min-w-avabl{
  min-width: -webkit-fill-available;
}

.otpContainer {
  display: flex;
  justify-content: space-around;
}
.bottom55{
  bottom: 55px;
}

.margin0auto{
  margin: 0 auto;
}

.otpInput {
  width: 3.5rem;
  height:3.5rem;
  flex-grow: 0;
  margin: 0 0.2rem;
  font-size: 0.9rem;
  text-align: center;
  text-indent: 1;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.clamp-lines__button {
  color: red;
}
.alignselfstart{
  align-self: start;
}
.alignselfcenter{
  align-self: center;
}
.webkitCenter{
  text-align: -webkit-center;
}
.mticketborder1{
  border-radius: 0px 0px 7px 7px;
}
.mticketborder2{
  border-radius: 7px 7px 0px 0px;
}
.PhoneInputInput{
  height: 2.75rem;
  border-radius: 1rem;
}
.fc-daygrid-day-events{
  font-size: .8em !important;
}


@media screen and (max-width:768px) 
{
  .fc-daygrid-day-events{
    font-size: .6em !important;
  }
  .fc-theme-standard{
    height: 70vh !important;
  }
  .w-screen{
    width: 28vh;
  }
}
@media screen and (min-width:769px) 
{
  .fc-daygrid-day-events{
    font-size: .8em !important;
  }
}

.mapiconcenter{
  text-align: -webkit-center;
}
.justify-space-evenly{
  justify-content: space-evenly;
}
.justify-space-between{
  justify-content: space-between;
}
.packages_box{
  border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(var(--c-neutral-200), var(--tw-border-opacity));
}
.align-center{
  align-items: center;
}
.h-half-screen {
  height: 85vh;
  width: 85%;
}
.clearSearchMargin{
  padding-left: 1.5rem;
  margin-top: 1.2rem;
}
.cartIcon{
  display: inline-block;
    border-radius: 1000px;
    background-color: #FF0004;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    position: absolute;
}
.select-search-container{
  width: 70% !important;
}
.select-search-input{
  border-radius: 20px !important;
}
.wil-avatar__name{
  font-size: xxx-large !important;
}
.width-fill{
  width:-webkit-fill-available
}
.text-align-left{
  text-align: left;
}
.scrollHide::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.bg-searchtabs {
  --tw-bg-opacity: 1;
  background: linear-gradient(#918b8b, rgb(95 95 92));
}
.gap-x-20{
  column-gap: 7rem;
}
ul{
list-style: disc;
margin-left: 10px;
padding-left: 25px;
}
p{
margin-bottom: 2px;
}